const page = window.location.href.split("//")[1].split("/")[1];

function App() {
    return (
    <div className="app">
        <Navbar>
            <Navitem link="home" visual="Home"/>
        </Navbar>
        <PageContent/>
        <OtherLinks/>
    </div>
    );
}

function Navbar(props) {
    return (
        <nav className="navbar">
            <ul className="navbar-nav"> {props.children} </ul>
        </nav>
    )
}

function Navitem(props) {
    return (
        <li className="nav-item-container">
            <a href={props.link} className="nav-item">
                <div className="item-visual">
                    {props.visual}
                </div>
            </a>
        </li>
    )
}

function OtherLinks() {

    return (
        <div className='other-links'>
            <ul>
                <li><a href="https://github.com/Icyfiremario" target="_blank" rel="noreferrer">My Github</a></li>
                <li><a href="https://discord.gg/rqW9wRuhJD" target="_blank" rel="noreferrer">Discord</a></li>
            </ul>
        </div>
    )
}

function PageContent() {

    switch (page) {
        //case "downloads": {
        //    function Download(props) {
        //        return (
        //            <li className="download-container">
        //                <a href={props.src} download={props.filename}>{props.children}</a>
        //            </li>
        //        );
        //    }
        //
        //    return (
        //        <div className="downloads-page">
        //            <ul className="download-options">
        //                <Download src={test} filename="test.txt">Test</Download>
        //            </ul>
        //        </div>
        //    )
        //}
        case "home":
        default:
            return (
                <div className="home-page">
                    <div className='info'>
                        <h1>Based Engine</h1>
                        <h2>About</h2>
                        <p>
                            Based Engine is an open source game engine system written in C++. There is a 3D engine that uses Vulkan and a 2D engine that uses SDL2
                            <br/>
                            Projects will be managed with the Based Engine Manager similar to how projects are managed in Unity and Unreal.
                        </p>
                        <h2>Roadmap</h2>
                        <p>These are features I plan to add before the engine is released for public use</p>
                        <ol>
                            <li>Finish 3D game engine rendering system</li>
                            <li>Port 2D game engine from SDL2 to Vulkan</li>
                            <li>Create audio pipeline</li>
                            <li>Create input API</li>
                            <li>Create entity componet system</li>
                            <li>Create project file system</li>
                            <li>Create project model manager</li>
                            <li>Create C++ based scripting system</li>
                            <li>Finish project manager</li>
                            <li>Create editor install manager</li>
                            <li>Create Manager installer</li>
                            <li>Write documentation</li>
                            <li>Release engine</li>
                        </ol>
                        <h2>Extra features</h2>
                        <p>These are features I may or may not add after the engine is done</p>
                        <ul>
                            <li>Fourm page for the engine</li>
                            <li>Package manager</li>
                        </ul>
                    </div>
                </div>
            )
    }
}


export default App;
